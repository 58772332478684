import React from "react";
import { Box } from "@chakra-ui/react";

const Link = (props) => {
  return (
    <Box
      as={"a"}
      display={"block"}
      target={"_blank"}
      color={"blue"}
      {...props}
    />
  );
};

Link.propTypes = {
  ...Box.propTypes,
};

export default Link;
