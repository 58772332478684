import {
  Box,
  ChakraProvider,
  Heading,
  HStack,
  Icon,
  Image,
} from "@chakra-ui/react";
import ReplyForm from "./ReplyForm";
import { getCalendarLink, mapsHref } from "./utils";
import Link from "./Link";
import { BsCalendarPlus } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";

function App() {
  return (
    <ChakraProvider>
      <Box p={4} mx={"auto"} maxWidth={"480px"}>
        <Box textAlign={"center"}>
          <Image src={"/unicorn.png"} mb={2} mx={"auto"} width={"40%"} />
          <Heading fontSize={"18px"}>Anabelle's Magical Celebration</Heading>
          <HStack
            as={Link}
            href={getCalendarLink()}
            spacing={2}
            alignItems={"center"}
            justifyContent={"center"}
            textAlign={"left"}
          >
            <Icon as={BsCalendarPlus} />
            <span>Sunday 13th of March, 2022. 11 - 1pm</span>
          </HStack>

          <HStack
            spacing={1}
            as={Link}
            alignItems={"center"}
            justifyContent={"center"}
            href={mapsHref}
          >
            <Icon as={IoLocationOutline} /> <span>Bramley Village Hall</span>
          </HStack>
          <Link href={mapsHref}>The St, Bramley, Tadley RG26 5BP</Link>
        </Box>
        <ReplyForm />
      </Box>
    </ChakraProvider>
  );
}

export default App;
