import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { borderColor } from "./theme";
import { getCalendarLink } from "./utils";

const ThankYou = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent mx={2}>
        <ModalHeader textAlign={"center"}>Thank You!</ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign={"center"}>We'll see you there!</ModalBody>

        <ModalFooter justifyContent={"center"}>
          <Button
            as={"a"}
            href={getCalendarLink()}
            target={"_blank"}
            variant={"outline"}
            borderColor={borderColor}
            _focus={{ borderColor }}
          >
            Add to calendar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ThankYou.propTypes = {
  ...Modal.propTypes,
};

export default ThankYou;
