import React, { useState } from "react";
import { useFormik } from "formik";
import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Textarea,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import ThankYou from "./ThankYou";
import { borderColor } from "./theme";

const ReplyForm = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      child: "",
      siblings: "",
      diet: "",
    },
    onSubmit: async (values) => {
      setLoading(true);

      if (!values.child && !values.siblings && !values.diet) {
        alert("Please enter child details");
        return;
      }

      await axios.post(`${process.env.REACT_APP_SERVER_URL}/reply`, {
        child: values.child,
        siblings: values.siblings,
        diet: values.diet,
      });

      setLoading(false);
      onOpen();
    },
  });

  const fields = [
    {
      id: "child",
      label: "Child Name",
      type: "text",
      helperText: "",
    },
    {
      id: "siblings",
      label: "Are siblings coming?",
      type: "textarea",
      helperText:
        "Add details for any siblings you would like to bring (age / allergies)",
    },
    {
      id: "diet",
      label: "Dietary Requirements/Allergies",
      type: "textarea",
      helperText: "Feel free to add any and all details for you and your child",
    },
  ];

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <VStack mb={3}>
          {fields.map((field) => (
            <FormControl key={field.id}>
              <FormLabel mb={0}>{field.label}</FormLabel>
              {field.helperText && (
                <FormHelperText mt={0} mb={1}>
                  {field.helperText}
                </FormHelperText>
              )}
              {field.type === "text" && (
                <Input
                  name={field.id}
                  onChange={formik.handleChange}
                  borderColor={borderColor}
                  _focus={{
                    borderColor,
                  }}
                />
              )}
              {field.type === "textarea" && (
                <Textarea
                  name={field.id}
                  onChange={formik.handleChange}
                  borderColor={borderColor}
                  _focus={{ borderColor }}
                />
              )}
            </FormControl>
          ))}
        </VStack>
        <Flex justifyContent={"center"}>
          <Button
            type={"submit"}
            variant={"outline"}
            borderColor={borderColor}
            _focus={{
              borderColor,
            }}
          >
            Submit
          </Button>
        </Flex>
      </form>
      <ThankYou isOpen={isOpen} onClose={onClose} />
    </>
  );
};

ReplyForm.propTypes = {};

export default ReplyForm;
