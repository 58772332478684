import { google, ics } from "calendar-link";
import UAParser from "ua-parser-js";

export const mapsHref =
  "https://www.google.com/maps/place/Bramley+Village+Hall/@51.3291684,-1.0711814,17z/data=!3m1!4b1!4m5!3m4!1s0x4874209101d767e1:0xa38cb6aad08c4a00!8m2!3d51.329152!4d-1.0689469";

export function getCalendarLink() {
  const event = {
    title: `Anabelle Busby's birthday party`,
    start: "2022-03-13 11:00:00",
    duration: [2, "hour"],
    location: "Bramley Village Hall",
    url: mapsHref,
  };

  const usParser = new UAParser();
  const os = usParser.getOS();
  const browser = usParser.getBrowser().name.toLowerCase();

  if (os.name === "iOS" && !/chrome/.test(browser)) {
    return ics(event);
  }

  return google(event);
}
